import { css, FlattenInterpolation, ThemeProps } from 'styled-components';
import { rem } from 'polished';

type BoxSide = 'top' | 'right' | 'bottom' | 'left';

const StandardBorder = (side: BoxSide = null) => css`
    ${side ? `border-${side}` : 'border'}: ${rem(1)} solid ${({ theme }) => theme.palette.MedGrey4};
`;

const LineClamp = (lines: number) => css`
    display: -webkit-box;
    -webkit-line-clamp: ${lines};
    -webkit-box-orient: vertical;
    overflow: hidden;
`;

interface Popup {
    tooltip?: boolean;
    visible?: boolean;
}

const Popup = ({ tooltip, visible }: Popup = {}) => css`
    width: ${rem(360)};
    box-sizing: border-box;
    background-color: ${({ theme }) => theme.palette.DarkGrey1};
    padding: ${rem(16)};
    border-radius: ${rem(12)};
    ${({ theme }) => theme.common.StandardBorder(null)}

    ${tooltip &&
    css`
        display: ${visible ? 'block' : 'none'};
        position: absolute;
        bottom: calc(100% + ${rem(24)});
        left: 50%;
        transform: translateX(-50%);
    `}
`;

const BoxShadowLight = css`
    box-shadow: 0px 0px ${rem(2)} 0px rgba(0, 0, 0, 0.75);
`;

const BoxShadowMedium = css`
    box-shadow: 0px ${rem(1)} ${rem(5)} ${rem(2)} rgba(0, 0, 0, 0.25);
`;

const BoxShadowHeavy = css`
    box-shadow: 0px ${rem(4)} ${rem(32)} ${rem(3)} rgba(0, 0, 0, 0.75);
`;

type Style = FlattenInterpolation<ThemeProps<any>>;

interface Common {
    StandardBorder: (side?: BoxSide) => Style;
    Popup: (props?: Popup) => Style;
    LineClamp: (lines: number) => Style;
    BoxShadowHeavy: Style;
    BoxShadowMedium: Style;
    BoxShadowLight: Style;
}

const Common: Common = {
    StandardBorder,
    Popup,
    LineClamp,
    BoxShadowHeavy,
    BoxShadowMedium,
    BoxShadowLight,
};

export default Common;
