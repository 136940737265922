import ReactDOM from 'react-dom';

export const OVERLAY_ROOT_ID = 'overlay-root';

const OverlayPortal: React.FC<any> = ({ children }) => {
    if (typeof window === 'undefined') {
        return null;
    }

    return ReactDOM.createPortal(children, document.getElementById(OVERLAY_ROOT_ID));
};

export default OverlayPortal;
