enum Palette {
    LightGrey1 = '#969696',
    LightGrey2 = '#727272',
    LightGrey3 = '#646464',
    LightGreyHighContrast = '#BABBBF',
    MedGrey1 = '#565656',
    MedGrey2 = '#484848',
    MedGrey3 = '#404040',
    MedGrey4 = '#323232',
    MedGreyTransparent4 = 'rgba(50, 50, 50, 0.35)',
    DarkGrey1 = '#242424',
    DarkGrey2 = '#161616',
    DarkGrey3 = '#080808',
    DarkGreen1 = '#304636',
    Black = '#000000',
    White = '#FFFFFF',
    Red = '#DB3236',
    Green = '#4EA866',
    Blue1 = '#2B7BEC',
    Blue2 = '#4286F4',
    Blue3 = '#56CCF2',
    BlueTransparent3 = 'rgba(86, 204, 242, 0.2)',
    BlueTransparent4 = 'rgba(86, 204, 242, 0.3)',
    RedTransparent1 = 'rgba(219, 50, 54, 0.15)',
    RedTransparent2 = 'rgba(219, 50, 54, 0.3)',
    DarkRed = '#CF1C2D',
    GreenTransparent1 = 'rgba(78, 168, 102, 0.15)',
    GreenTransparent2 = 'rgba(78, 168, 102, 0.3)',
    Yellow = '#F6BF4F',
    YellowTransparent1 = 'rgba(246, 191, 79, 0.15)',
    YellowTransparent3 = 'rgba(246, 191, 79, 0.30)',
    Orange = '#F6774F',
    Pink = '#FED4D4',
}

export default Palette;
